<template>
  <div class="edit-upload">
    <div class="file-wrap">
      <div class="item item_none" v-show="!form.data.grzp" @click="click('grzp')">
        <img src="./img/img1.png" alt="照片" />
        <div class="text">暂无数据</div>
      </div>
      <div class="item" v-show="form.data.grzp">
        <img
          class="grzp_img"
          :src="'https://jxssft.rmpsy.jxtongke.com/api/'+form.data.grzp.path"
          alt="照片"
          v-if="form.data.grzp"
        />
      </div>

      <div class="item item_none" v-show="!form.data.sfztxm" @click="click('sfztxm')">
        <img src="./img/img2.png" alt="身份证头像面" />
        <div class="text">暂无数据</div>
      </div>
      <div class="item" v-show="form.data.sfztxm">
        <img
          class="sfztxm_img"
          :src="'https://jxssft.rmpsy.jxtongke.com/api/'+form.data.sfztxm.path"
          alt="照片"
          v-if="form.data.sfztxm"
        />
      </div>

      <div class="item item_none" v-show="!form.data.sfzghm" @click="click('sfzghm')">
        <img src="./img/img3.png" alt="身份证国徽面" />
        <div class="text">暂无数据</div>
      </div>
      <div class="item" v-show="form.data.sfzghm">
        <img
          class="sfzghm_img"
          :src="'https://jxssft.rmpsy.jxtongke.com/api/'+form.data.sfzghm.path"
          alt="照片"
          v-if="form.data.sfzghm"
        />
      </div>
    </div>
    <div class="info">
      备注：带
      <span>*</span>号的为必填
    </div>
    <div class="nextStep">
      <el-button type="primary" @click="submit(true)">提交修改</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'upload',
  props: { info: Object },
  components: {},
  data() {
    return {
      form: {
        data: {
          grzp: '', //照片
          sfztxm: '', //身份证头像面
          sfzghm: '', //身份证国徽面
        },
        placeholder: {
          grzp: '请输入照片', //照片
          sfztxm: '请输入身份证头像面', //身份证头像面
          sfzghm: '请输入身份证国徽面', //身份证国徽面
        },
      },
    };
  },
  methods: {
    click(name) {
      this.$refs[name].click();
    },
    toggle(info) {
      let { grzp, sfztxm, sfzghm } = info;
      this.form.data = { grzp, sfztxm, sfzghm };
    },
    submit(submit) {
      if (submit) {
        this.$emit('changeInfo', this.form.data);
        this.$emit('submit');
      } else {
        this.$emit('changeInfo', this.form.data);
        this.$emit('canChangeComponent', true);
      }
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.edit-upload {
  .file-wrap {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    box-sizing: border-box;
    .item {
      width: 428px;
      height: 270px;
      border: 1px dashed #d9d9d9;
      display: flex;
      border-radius: 8px;
      position: relative;
      img {
        margin: auto;
        max-height: 80%;
        max-width: 80%;
      }
      .text {
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 10px;
        .blue {
          color: #409eff;
        }
      }
    }
    .item_none{
      opacity: 0.5;
    }
    .item:nth-of-type(-n+3){
      margin-right: 10px;
    }
  }
}
.info {
  margin-top: 100px;
  font-size: 14px;
  color: #0079fe;
  span {
    color: #f56c6c;
  }
}
.nextStep {
  text-align: center;
}
</style>
